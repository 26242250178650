import { Action, ActionCreatorsMapObject } from 'redux';

export enum ITopicStatus {
    Inactive = 0,
    Active = 1,
    OnlyReading = 2
}

export interface IPost {
    content: string;
    createdAt: string;
    updatedAt?: string;
    id: string;
    posts: IPost[];
    user: IUser;
    attachment?: string;
}

export interface IUser {
    avatar?: string;
    id: string;
    name: string;
    userId: number;
    type?: number;
}

export interface IPagination {
    hasMoreItems: boolean;
    bookmark?: string;
}

export interface ITopicResponse {
    id: string;
    slug: string;
    reference: string;
    posts: IPost[];
    status: ITopicStatus;
    createdAt: string;
    title: string;
}

export interface IPostsResponse {
    data: IPost[];
    pagination: IPagination;
}

export interface IPostResponse {
    content: string;
    createdAt: string;
    updatedAt?: string;
    id: string;
    posts: IPost[];
    user: IUser;
    attachment?: string;
}

export interface IGetUpdatedPostsResponse {
    idPost: string;
    lastestUpdate: string;
    hasChanges: boolean;
}

// ----------

export interface IPostsFormattedItemsToCheckUpdates {
    IdPost: string;
    LastestUpdate: string;
}

export interface ITopic {
    isLoading: boolean;
    data: ITopicResponse;
}

export interface IPostsFilters {
    quantity: number;
    withoutAnswers: boolean;
    bookmark?: string;
}

export interface IPosts {
    items: IPost[];
    filters: IPostsFilters;
    isLoading: boolean;
    hasMore: boolean;
    formattedItemsToCheckUpdates: IPostsFormattedItemsToCheckUpdates[];
    deletedItemsCount: number;
}

export interface IVideoResponse {
    id: number;
    title: string;
    url: string;
}

export interface IVideo {
    isLoading: boolean;
    data: IVideoResponse;
}

export interface IPostToAddAttachment {
    postId: string;
    answerId: string;
}

export interface IAnswerQuestionsState {
    topic: ITopic;
    posts: IPosts;
    video: IVideo;
    newPostsQuantity: number;
    postsToUpdate: string[];
    postIdToCreateOrEditAnswers?: string;
    postToAddAttachment?: IPostToAddAttachment;
}

// ----------

export interface IAnswerQuestionsTopicPayload {
    topicId: string;
}

export interface IAnswerQuestionsTopicVideoPayload {
    learningObjectId: string;
}

export interface IAnswerQuestionsPostsPayload {
    topicId: string;
    quantity: number;
    withoutAnswers: boolean;
    bookmark?: string;
    isRestarting?: boolean;
}

export interface IAnswerQuestionsHasNewPostsPayload {
    topicId: number;
    quantity: number;
    withoutAnswers: boolean;
}

export interface IAnswerQuestionsHasUpdatedPostsPayload {
    topicId: number;
    posts: IPostsFormattedItemsToCheckUpdates[];
}

export interface IAnswerQuestionsUpdatedPostsPayload {
    posts: string[];
}

export interface ICreateAnswerQuestionsPostReplyPayload {
    postId: string;
    content: string;
}

export interface IEditAnswerQuestionsPostReplyPayload {
    postId: string;
    replyId: string;
    content: string;
}

export interface IDeleteAnswerQuestionsPostReplyPayload {
    postId: string;
    replyId: string;
}

export interface IDeleteAnswerQuestionsPostPayload {
    postId: string;
}

export interface IAddAnswerQuestionsPostAnswerAttachmentPayload {
    topicId: string;
    postId: string;
    answerId: string;
    file: File | Blob;
    fileName: string;
}

export interface IDeleteAnswerQuestionsPostAnswerAttachmentPayload {
    topicId: string;
    postId: string;
    answerId: string;
}

// ----------

export interface IResponsePayload<Response, Variables> {
    response: Response;
    variables: Variables;
}

export interface IPostsResponseVariables {
    withoutAnswers: boolean;
    isRestarting?: boolean;
    serverDate: number;
}

export interface ICreatePostReplyResponseVariables {
    postId: string;
}

export interface IEditPostReplyResponseVariables {
    postId: string;
    replyId: string;
    content: string;
}

export interface IDeletePostReplyResponseVariables {
    postId: string;
    replyId: string;
}

export interface IDeletePostResponseVariables {
    postId: string;
}

export interface IUpdatedPostsResponseVariables {
    serverDate: number;
}

export interface IAddAnswerQuestionsPostAnswerAttachmentResponseVariables {
    postId: string;
    answerId: string;
}

export interface IDeleteAnswerQuestionsPostAnswerAttachmentResponseVariables {
    postId: string;
    answerId: string;
}

export interface IAnswerQuestionsActions {
    getAnswerQuestionsTopicRequest: (payload: IAnswerQuestionsTopicPayload) => Action<void>;
    getAnswerQuestionsTopicSuccess: (payload: IResponsePayload<ITopicResponse, {}>) => Action<void>;
    getAnswerQuestionsTopicFailure: (payload: any) => Action<void>;

    getAnswerQuestionsTopicVideoRequest: (payload: IAnswerQuestionsTopicVideoPayload) => Action<void>;
    getAnswerQuestionsTopicVideoSuccess: (payload: IResponsePayload<IVideoResponse, {}>) => Action<void>;
    getAnswerQuestionsTopicVideoFailure: (payload: any) => Action<void>;

    getAnswerQuestionsPostsRequest: (payload: IAnswerQuestionsPostsPayload) => Action<void>;
    getAnswerQuestionsPostsSuccess: (payload: IResponsePayload<IPostsResponse, IPostsResponseVariables>) => Action<void>;
    getAnswerQuestionsPostsFailure: (payload: any) => Action<void>;

    getHasNewPostsRequest(payload: IAnswerQuestionsHasNewPostsPayload): Action<void>;
    getHasNewPostsSuccess(payload: IResponsePayload<IPostsResponse, {}>): Action<void>;
    getHasNewPostsFailure(payload: any): Action<void>;

    getHasUpdatedPostsRequest(payload: IAnswerQuestionsHasUpdatedPostsPayload): Action<void>;
    getHasUpdatedPostsSuccess(payload: IResponsePayload<IGetUpdatedPostsResponse[], {}>): Action<void>;
    getHasUpdatedPostsFailure(payload: any): Action<void>;

    getUpdatedPostsRequest(payload: IAnswerQuestionsUpdatedPostsPayload): Action<void>;
    getUpdatedPostsSuccess(payload: IResponsePayload<IPostResponse[], IUpdatedPostsResponseVariables>): Action<void>;
    getUpdatedPostsFailure(payload: any): Action<void>;

    createAnswerQuestionsPostReplyRequest: (payload: ICreateAnswerQuestionsPostReplyPayload) => Action<void>;
    createAnswerQuestionsPostReplySuccess: (payload: IResponsePayload<IPostResponse, ICreatePostReplyResponseVariables>) => Action<void>;
    createAnswerQuestionsPostReplyFailure: (payload: any) => Action<void>;

    editAnswerQuestionsPostReplyRequest: (payload: IEditAnswerQuestionsPostReplyPayload) => Action<void>;
    editAnswerQuestionsPostReplySuccess: (payload: IResponsePayload<{}, IEditPostReplyResponseVariables>) => Action<void>;
    editAnswerQuestionsPostReplyFailure: (payload: any) => Action<void>;

    deleteAnswerQuestionsPostReplyRequest: (payload: IDeleteAnswerQuestionsPostReplyPayload) => Action<void>;
    deleteAnswerQuestionsPostReplySuccess: (payload: IResponsePayload<{}, IDeletePostReplyResponseVariables>) => Action<void>;
    deleteAnswerQuestionsPostReplyFailure: (payload: any) => Action<void>;

    deleteAnswerQuestionsPostRequest: (payload: IDeleteAnswerQuestionsPostPayload) => Action<void>;
    deleteAnswerQuestionsPostSuccess: (payload: IResponsePayload<{}, IDeletePostResponseVariables>) => Action<void>;
    deleteAnswerQuestionsPostFailure: (payload: any) => Action<void>;

    addAnswerQuestionsPostAnswerAttachmentRequest: (payload: IAddAnswerQuestionsPostAnswerAttachmentPayload) => Action<void>;
    addAnswerQuestionsPostAnswerAttachmentSuccess: (payload: IResponsePayload<IPostResponse, IAddAnswerQuestionsPostAnswerAttachmentResponseVariables>) => Action<void>;
    addAnswerQuestionsPostAnswerAttachmentFailure: (payload: any) => Action<void>;

    deleteAnswerQuestionsPostAnswerAttachmentRequest: (payload: IDeleteAnswerQuestionsPostAnswerAttachmentPayload) => Action<void>;
    deleteAnswerQuestionsPostAnswerAttachmentSuccess: (payload: IResponsePayload<{}, IDeleteAnswerQuestionsPostAnswerAttachmentResponseVariables>) => Action<void>;
    deleteAnswerQuestionsPostAnswerAttachmentFailure: (payload: any) => Action<void>;

    clearAnswerQuestions: () => Action<void>;
}

export type AnswerQuestionsActionsType =
    | 'GET_ANSWER_QUESTIONS_TOPIC_REQUEST'
    | 'GET_ANSWER_QUESTIONS_TOPIC_SUCCESS'
    | 'GET_ANSWER_QUESTIONS_TOPIC_FAILURE'
    | 'GET_ANSWER_QUESTIONS_TOPIC_VIDEO_REQUEST'
    | 'GET_ANSWER_QUESTIONS_TOPIC_VIDEO_SUCCESS'
    | 'GET_ANSWER_QUESTIONS_TOPIC_VIDEO_FAILURE'
    | 'GET_ANSWER_QUESTIONS_POSTS_REQUEST'
    | 'GET_ANSWER_QUESTIONS_POSTS_SUCCESS'
    | 'GET_ANSWER_QUESTIONS_POSTS_FAILURE'
    | 'GET_ANSWER_QUESTIONS_HAS_NEW_POSTS_REQUEST'
    | 'GET_ANSWER_QUESTIONS_HAS_NEW_POSTS_SUCCESS'
    | 'GET_ANSWER_QUESTIONS_HAS_NEW_POSTS_FAILURE'
    | 'GET_ANSWER_QUESTIONS_HAS_UPDATED_POSTS_REQUEST'
    | 'GET_ANSWER_QUESTIONS_HAS_UPDATED_POSTS_SUCCESS'
    | 'GET_ANSWER_QUESTIONS_HAS_UPDATED_POSTS_FAILURE'
    | 'GET_ANSWER_QUESTIONS_UPDATED_POSTS_REQUEST'
    | 'GET_ANSWER_QUESTIONS_UPDATED_POSTS_SUCCESS'
    | 'GET_ANSWER_QUESTIONS_UPDATED_POSTS_FAILURE'
    | 'CREATE_ANSWER_QUESTIONS_POST_REPLY_REQUEST'
    | 'CREATE_ANSWER_QUESTIONS_POST_REPLY_SUCCESS'
    | 'CREATE_ANSWER_QUESTIONS_POST_REPLY_FAILURE'
    | 'EDIT_ANSWER_QUESTIONS_POST_REPLY_REQUEST'
    | 'EDIT_ANSWER_QUESTIONS_POST_REPLY_SUCCESS'
    | 'EDIT_ANSWER_QUESTIONS_POST_REPLY_FAILURE'
    | 'DELETE_ANSWER_QUESTIONS_POST_REPLY_REQUEST'
    | 'DELETE_ANSWER_QUESTIONS_POST_REPLY_SUCCESS'
    | 'DELETE_ANSWER_QUESTIONS_POST_REPLY_FAILURE'
    | 'DELETE_ANSWER_QUESTIONS_POST_REQUEST'
    | 'DELETE_ANSWER_QUESTIONS_POST_SUCCESS'
    | 'DELETE_ANSWER_QUESTIONS_POST_FAILURE'
    | 'ADD_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_SUCCESS'
    | 'ADD_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_FAILURE'
    | 'ADD_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_REQUEST'
    | 'DELETE_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_REQUEST'
    | 'DELETE_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_SUCCESS'
    | 'DELETE_ANSWER_QUESTIONS_POST_ANSWER_ATTACHMENT_FAILURE'
    | 'CLEAR_ANSWER_QUESTIONS';

export interface IAnswerQuestionsActionsCreators extends IAnswerQuestionsActions, ActionCreatorsMapObject {}
