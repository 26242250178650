import React from 'react';

// Components
import Button from 'components/button';
import Dropdown from 'components/dropdown';

// Helpers
import { connect } from 'react-redux';
import { IAuthActions } from 'store/types/IAuth';
import { ProfileType } from 'utils/enums/profile';
import { Dispatch, bindActionCreators } from 'redux';
import { IMenuItem } from 'utils/interfaces/ICredentials';
import { Creators as AuthActions } from 'store/ducks/auth';
import { IReduxStore } from 'utils/interfaces/IReduxStore';

// Assets
import { NavItem, NavContainer, NavWrapper, NavButton, NavbarPreviewActions, NavbarPreview } from './styles';

interface IProps {
    navMenu: any;
    navMenuPreview: any;
    authActions: IAuthActions;
    isTeacher: boolean;
}

const Nav = ({ navMenu, navMenuPreview, authActions, isTeacher }: IProps) => {
    if (!navMenu) {
        return <NavContainer />;
    }

    // Exibe o menu de professor
    if (isTeacher) {
        return (
            <NavContainer>
                <NavWrapper>
                    <NavItem>
                        <NavButton exact={true} to="/app">
                            Home
                        </NavButton>
                    </NavItem>

                    <NavItem hasSubmenu={true}>
                        <NavButton exact={true} to={'/#'}>
                            Fórum
                        </NavButton>
                        <Dropdown
                            isOpen={true}
                            items={[
                                {
                                    name: 'Tópicos',
                                    path: '/app/forum/topicos'
                                }
                            ]}
                        />
                    </NavItem>
                </NavWrapper>
            </NavContainer>
        );
    }

    return (
        <NavContainer>
            {!!navMenuPreview && (
                <NavbarPreviewActions>
                    <Button variant="danger" size="small" onClick={() => authActions.removeMenuPreview()}>
                        Fechar Preview
                    </Button>
                </NavbarPreviewActions>
            )}

            <NavWrapper>
                {!!navMenuPreview && !!navMenuPreview.menus && (
                    <NavbarPreview>
                        {navMenuPreview.menus.map((item: IMenuItem, index: any) => (
                            <NavItem hasSubmenu={!!item.menus && !!item.menus.length} key={index}>
                                <NavButton to={item.path || '/#'}>{item.name}</NavButton>
                                {!!item.menus && item.menus.length > 0 && <Dropdown isOpen={true} items={item.menus} />}
                            </NavItem>
                        ))}
                    </NavbarPreview>
                )}

                {!navMenuPreview &&
                    !!navMenu.menus &&
                    navMenu.menus.map((item: IMenuItem, index: any) => (
                        <NavItem hasSubmenu={Boolean(item.menus?.length)} key={index}>
                            <NavButton exact={true} to={item.path || '/#'}>
                                {item.name}
                            </NavButton>
                            {!!item.menus && item.menus.length > 0 && <Dropdown isOpen={true} items={item.menus} />}
                        </NavItem>
                    ))}
            </NavWrapper>
        </NavContainer>
    );
};

const mapStateToProps = ({ auth }: IReduxStore) => {
    const isTeacher = !!auth.credentials.teacher && Array.isArray(auth.profiles) && !auth.profiles.some((item) => item.type === ProfileType.God);

    return { navMenu: auth.menu, navMenuPreview: auth.menuPreview, isTeacher };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch) as any
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
